// Generated by Framer (58a021c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Clipboard from "https://framerusercontent.com/modules/Hj20QU19p80mpYsvesiZ/EqB2oGdmJ81urPOd5L66/Clipboard.js";
const ClipboardFonts = getFonts(Clipboard);

const enabledGestures = {ppNY5nbdG: {hover: true}};

const cycleOrder = ["ppNY5nbdG"];

const variantClassNames = {ppNY5nbdG: "framer-v-1hocwcr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; mail?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ppNY5nbdG", mail: N_C9inTN8 = "Tanjimislam27@gmail.com", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ppNY5nbdG", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-A3cXM", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1hocwcr", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ppNY5nbdG"} ref={ref} style={{...style}} {...addPropertyOverrides({"ppNY5nbdG-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1inrlst-container"} layoutDependency={layoutDependency} layoutId={"Ct4IJgYx9-container"}><Clipboard borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} color={"var(--token-883132e0-45d6-4b2b-bb30-29dc404e311e, rgb(29, 29, 31)) /* {\"name\":\"text color\"} */"} content={N_C9inTN8} fill={"rgb(255, 255, 255)"} font fontFamily={"clash display"} fontSize={36} fontWeight={700} height={"100%"} id={"Ct4IJgYx9"} isMixedBorderRadius={false} label={N_C9inTN8} layoutId={"Ct4IJgYx9"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} topLeftRadius={0} topRightRadius={0} width={"100%"} {...addPropertyOverrides({"ppNY5nbdG-hover": {color: "var(--token-69d0d919-5106-45a3-9073-8a95b5ccacb9, rgb(255, 90, 0)) /* {\"name\":\"orange\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-A3cXM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-A3cXM .framer-122f4d8 { display: block; }", ".framer-A3cXM .framer-1hocwcr { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-A3cXM .framer-1inrlst-container { flex: none; height: auto; position: relative; width: auto; }", ".framer-A3cXM .framer-v-1hocwcr .framer-1hocwcr { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-A3cXM .framer-1hocwcr { gap: 0px; } .framer-A3cXM .framer-1hocwcr > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-A3cXM .framer-1hocwcr > :first-child { margin-left: 0px; } .framer-A3cXM .framer-1hocwcr > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 471
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"TcM0sCdJ0":{"layout":["auto","auto"]}}}
 * @framerVariables {"N_C9inTN8":"mail"}
 */
const FramerrOlDjThvb: React.ComponentType<Props> = withCSS(Component, css, "framer-A3cXM") as typeof Component;
export default FramerrOlDjThvb;

FramerrOlDjThvb.displayName = "Mail";

FramerrOlDjThvb.defaultProps = {height: 44, width: 471};

addPropertyControls(FramerrOlDjThvb, {N_C9inTN8: {defaultValue: "Tanjimislam27@gmail.com", displayTextArea: true, title: "Mail", type: ControlType.String}} as any)

addFonts(FramerrOlDjThvb, [...ClipboardFonts])